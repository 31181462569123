// import _ from 'lodash';

export default {  
  setError(state, value){
    state.error = value;
  }, 
  setAlert(state, value){    
    state.alert = value;
  }, 
  setModal(state, value){    
    state.modal = value;
  }, 
};