import {cloneDeep} from 'lodash';

export default {    
    loading: state => state.loading,
    sitePlugin: state => cloneDeep(state.sitePlugin),    
    plugins: state => cloneDeep(state.plugins),    
    fanPages: state => cloneDeep(state.fanPages),
    fanPageViewKey: state => state.fanPageViewKey,
    fbAppId: state => state.fbAppId,
    fbModalActive: state => state.fbModalActive,
    fbPageSelected: state => cloneDeep(state.fbPageSelected),
};