<template>
    <div>        
        <modal-error :key="error?.key" v-if="error?.show"></modal-error>
        <modal-alert :key="alert?.key" v-if="alert?.show"></modal-alert>
        <component :key="modal?.key" v-if="modal && 'component' in modal" :is="modal.component"></component>
    </div>
</template>
<script>
import ModalError from './ModalError';
import ModalAlert from './ModalAlert';
import ModalListCompanies from './ModalListCompanies';

import {
    mapGetters
} from 'vuex';

export default {
    components: {
        ModalError,
        ModalAlert,
        ModalListCompanies
    },
    computed: {
        ...mapGetters('Modal', ['error', 'alert', 'modal'])
    },
}
</script>
