import auth from "./auth";
import marketing from "./marketing";
import companies from "./companies";
import manager from "./manager";
import dealers from "./dealers";


export default [].concat(auth)
.concat(marketing)
.concat(companies)
.concat(manager)
.concat(dealers);

// // Obtém uma função de contexto que carrega os módulos em ./web, ./auth, ./marketing, etc.
// const context = require.context('./', true, /^(?!.*index\.js$).*\.js$/)

// // Obtém as chaves dos módulos e os importa com a função 'map()'
// const routes = context.keys().map(key => context(key).default)

// // Exporta todas as rotas concatenadas
// export default [].concat(routes)