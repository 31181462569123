<template>
    <li>
        <template v-if="getLinkMenu != '/'">
            <router-link class="group flex justify-between space-x-2 rounded-lg p-2 
            tracking-wide text-slate-800 outline-none transition-all 
            hover:bg-slate-100 focus:bg-slate-100 dark:text-navy-100 
            dark:hover:bg-navy-600 dark:focus:bg-navy-600" :to="getLinkMenu"
                @click.native="setLinkMenuSelected(linkMenu)"
                :class="{'bg-slate-150 bg-gradient-to-r from-purple-500 to-purple-600 text-white' : menuActive}">
                <div class="flex items-center space-x-2">
                    <menu-icon :menu="linkMenu"></menu-icon>
                    <span :class="{
                        'text-slate-800 dark:text-navy-100': !menuActive,
                        'text-white': menuActive
                    }">{{linkMenu.label}}</span>
                </div>
                <span v-if="linkMenu.counter"
                    class="badge rounded-full text-xs bg-success text-white">{{linkMenu.counter}}</span>
            </router-link>
        </template>
        <template v-else>
            <a class="group flex justify-between space-x-2 rounded-lg p-2 
            tracking-wide text-slate-800 outline-none transition-all 
            hover:bg-slate-100 focus:bg-slate-100 dark:text-navy-100 
            dark:hover:bg-navy-600 dark:focus:bg-navy-600" 
            :href="linkBackendOld">
                <div class="flex items-center space-x-2">
                    <menu-icon :menu="linkMenu"></menu-icon>
                    <span :class="{
                        'text-slate-800 dark:text-navy-100': !menuActive,
                        'text-white': menuActive
                    }">{{linkMenu.label}}</span>
                </div>
                <span v-if="linkMenu.counter"
                    class="badge rounded-full bg-success text-white">{{linkMenu.counter}}</span>
            </a>
        </template>
    </li>
</template>
<script>
import mixinsMenu from '../mixinsMenu';

export default{
    mixins: [mixinsMenu]
}
</script>