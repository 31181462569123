<template>
    <div class="fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
        v-show="true" role="dialog">
        <div class="absolute inset-0 bg-slate-900/60 backdrop-blur transition-opacity duration-300"></div>
        <div class="relative max-w-lg rounded-lg bg-white px-4 py-10 text-center transition-opacity duration-300 dark:bg-navy-700 sm:px-5"
            style="min-width: 360px;">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 508 508" class="inline h-28 w-28" style="enable-background:new 0 0 508 508;" xml:space="preserve">
            <circle style="fill:#FD8469;" cx="254" cy="254" r="254"/>
            <path style="fill:#FFFFFF;" d="M424.1,336.3L270.2,64c-7.1-12.6-25.2-12.6-32.3,0l-154,272.3c-7,12.4,1.9,27.7,16.2,27.7H408
                C422.2,364,431.1,348.7,424.1,336.3z"/>
            <polygon style="fill:#FFD05B;" points="109.2,337.4 254,81.2 398.8,337.4 "/>
            <g>
                <path style="fill:#FFFFFF;" d="M272.7,197c0,35.1-8.4,89.1-18.7,89.1c-10.4,0-18.7-54-18.7-89.1s8.4-38.1,18.7-38.1
                    C264.4,158.9,272.7,161.9,272.7,197z"/>
                <circle style="fill:#FFFFFF;" cx="254" cy="304.4" r="10.6"/>
            </g>
            </svg>
          

            <div class="mt-4">
                <h2 class="text-2xl text-slate-700 dark:text-navy-100">
                    Error
                </h2>
                <p class="mt-2" v-html="error.message"></p>
                <button @click="close"
                    class="btn mt-6 bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90">
                    {{$t("button.close")}}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters('Modal', ['error'])
    },
    methods: {
        ...mapActions('Modal', ['setError']),
        close(){                        
            if(this.error && 'url' in this.error){
                this.$router.push({
                    name: this.error.url,
                })
            }
            this.setError({show: false});
        }
    },
}
</script>