import { axiosWithoutLoading } from "@/utils";

export default {
    setCustomer(context, value) {
        context.commit("setCustomer", value)
    },        
     async getCustomer(context, params){   
        context.commit('setLoading', true);            
        return await axiosWithoutLoading({
            method: 'GET',
            url: 'company/customers/'+params.id,                           
        }).then(response => response.data.data)
        .then(data => {            
            context.commit("setCustomer", data);

            context.commit('setLoading', false);
            return data;
        })
    },    
};