import Vue from 'vue'
import VueRouter from 'vue-router'
import listRoutes from './routes';
import VueBodyClass from 'vue-body-class';
import { protectedPage } from '@/utils';
import store from '@/store';
import { registerVuexModule } from '@/store/registerVuexModule';

Vue.use(VueRouter)

const baseRoutes = [  
  {
    path: '/',
    name: 'home',   
    component: () => import('../views/Home'),
    beforeEnter(to, from, next){      
      protectedPage(to, from, next);
    },
    meta: { bodyClass: 'is-header-blur' }
  },
  {
    path: '/payment-not-found',
    name: 'payment-not-found',   
    beforeEnter(to, from, next){      
      protectedPage(to, from, next);
    },
    component: () => import('../views/PlanInactive.vue')
  },
  {
    path: '/profile',
    name: 'profile',   
    beforeEnter(to, from, next){      
      protectedPage(to, from, next);
    },
    component: () => import('../views/Profile'),
    meta: { bodyClass: 'is-header-blur' }
  },
  {
    path: '/404',
    name: '404',   
    component: () => import('../views/404View.vue')
  },
  {
    path: '*',
    redirect: '/404'
  }
];


const routes = baseRoutes.concat(listRoutes);
// const routes = baseRoutes;
const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})

// (async () => {
//   const routes = await listRoutes();
//   router.addRoutes(routes);
// })();

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach(async (to, from, next) => { 
  if(['recoverPassword', 'login', 'resetPassword'].indexOf(to.name) === -1){
    protectedPage(to, from, next);
  }

  let company = await store.getters['User/company'];

  //redireciona se nao pagou a conta
  let companyPayment = company && Object.keys(company).length && company.agency.has_active_billings && (!company.is_trial && !company.has_plan_active);
  let excludeNames = ['billings', 'payment-not-found'];
  
  if(companyPayment && excludeNames.indexOf(to.name) === -1 && excludeNames.indexOf(from.name) === -1){            
      next({
          name: 'payment-not-found'
      });
      return false;
  }

  store.dispatch('Site/setRouterBack', from.name, {root: true})

  vueBodyClass.guard(to, next);
  await registerVuexModule(to, from);
});
router.afterEach(() => {
  store.dispatch('Site/loadHeaderMenu', {root: true})
})

export default router
