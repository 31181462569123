<template>
    <nav class="header print:hidden">
        <main-header></main-header>
    </nav>
</template>
<script>
import MainHeader from './MainHeader';

export default {
    components:{
        MainHeader
    }
}
</script>