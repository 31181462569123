import i18n from '@/i18n';

export default [
    {
        path: 'billings/billings',
        name: 'billings-index',
        component: () => import('@/views/BackendDefault'),
        meta: {
            breadcrumb: i18n.t("view.billings.title")
        },
        children: [{
                path: 'list',
                name: 'billings',
                component: () => import('@/views/Manager/Billings/Billings/List'),
                meta: {
                    breadcrumb: i18n.t("view.list")
                },
            },
            // {
            //     path: 'create',
            //     name: 'billings-create',
            //     component: () => import('@/views/BackendDefault/CreateUpdate'),
            //     meta: {
            //         breadcrumb: i18n.t("view.create")
            //     },
            // },
            // {
            //     path: 'update/:id',
            //     name: 'billings-update',
            //     component: () => import('@/views/BackendDefault/CreateUpdate'),
            //     meta: {
            //         breadcrumb: i18n.t("view.update")
            //     },
            // },
        ]
    },    
];