<template>
    <ul class="mt-4 space-y-1">
        <LinkLI v-for="(link, index) in listMenuLi" :key="index" :linkMenu="link"></LinkLI>
    </ul>
</template>
<script>
import { mapGetters } from 'vuex';
import LinkLI from './LinkLI.vue';

export default {
    components: {
        LinkLI,
    },
    props: {
        listMenuLi: Object
    },    
}
</script>
