<template>
    <div id="profile-wrapper" class="flex">
        <button id="profile-ref" class="avatar h-12 w-12">
            <img class="rounded-full" :src="user.avatar" alt="avatar" />
            <span
                class="absolute right-0 h-3.5 w-3.5 rounded-full border-2 border-white bg-success dark:border-navy-700"></span>
        </button>
        <div id="profile-box" class="popper-root fixed">
            <div
                class="popper-box w-64 rounded-lg border border-slate-150 bg-white shadow-soft dark:border-navy-600 dark:bg-navy-700">
                <div class="flex items-center space-x-4 rounded-t-lg bg-slate-100 py-5 px-4 dark:bg-navy-800">
                    <div class="avatar h-14 w-14">
                        <img class="rounded-full" :src="user.avatar" alt="avatar" />
                    </div>
                    <div>
                        <span href="#"
                                class="text-base font-medium text-slate-700 hover:text-primary focus:text-primary dark:text-navy-100 dark:hover:text-accent-light dark:focus:text-accent-light">
                                {{user.name}}
                        </span>
                        <p class="text-xs text-slate-400 dark:text-navy-300">
                            {{user.role?.name}}
                        </p>
                    </div>
                </div>
                <div class="flex flex-col pt-2 pb-5">
                    <router-link :to="{name: 'profile'}"
                        class="group flex items-center space-x-3 py-2 px-4 tracking-wide outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600">
                        <div class="flex h-8 w-8 items-center justify-center rounded-lg bg-warning text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4.5 w-4.5" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                            </svg>
                        </div>

                        <div>
                            <h2
                                class="font-medium text-slate-700 transition-colors group-hover:text-primary group-focus:text-primary dark:text-navy-100 dark:group-hover:text-accent-light dark:group-focus:text-accent-light">
                                {{$t('view.profile.buttons.profile')}}
                            </h2>
                            <div class="text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                                {{$t('view.profile.buttons.profileDetails')}}
                            </div>
                        </div>
                    </router-link>
                    
                    <div class="mt-3 px-4">
                        <button
                            @click="logout"
                            class="btn h-9 w-full space-x-2 bg-primary text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                            </svg>
                            <span>Logout</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Popper from '@/classes/Popper';
import {
    mapActions,
    mapGetters
} from "vuex";

export default {
    computed: {
        ...mapGetters('User', ['user']),
    },
    methods: {
        ...mapActions('User', ['logout']),
    },
    mounted() {
        this.$nextTick(() => {
            const PROFILE_WRAPPER_ID = "profile-wrapper";
            const PROFILE_REF_ID = "profile-ref";
            const PROFILE_BOX_ID = "profile-box";
            const config = {
                placement: "right-end",
                modifiers: [{
                    name: "offset",
                    options: {
                        offset: [0, 12],
                    },
                }, ],
            };

            this.popperProfile = new Popper(
                `#${PROFILE_WRAPPER_ID}`,
                `#${PROFILE_REF_ID}`,
                `#${PROFILE_BOX_ID}`,
                config
            );
        });
    },
}
</script>