export default {    
    setError(context, value){        
        if ('modalShow' in value && value.modalShow == false) {
            return;
        }
        let error = {};
        error.show = true;
        error.key = (new Date).getTime();
        let props = Object.assign(error, value);           
        context.commit('setError', props);
    },
    setAlert(context, value){
        let alert = {};
        alert.show = true;
        alert.key = (new Date).getTime();
        let props = Object.assign(alert, value);        
        context.commit('setAlert', props);
    },
    setModal(context, value){
        let modal = {};
        modal.show = true;
        modal.key = (new Date).getTime();
        let props = Object.assign(modal, value);        
        context.commit('setModal', props);
    },    
};