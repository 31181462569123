import leads from "./leads";

const children = [].concat(leads);
const routes = [{
    path: '/marketing',    
    component: () => import('@/views/BackendDefault'),
    children: children,    
    meta: {
        bodyClass: 'has-min-sidebar is-header-blur',
        breadcrumb: 'Marketing',
    }
}]

export default routes;
