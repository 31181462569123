import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
    namespaced: true,
    state: {
        user: {},
        token: null,
        companyToken: null,
        company: {}
    },
    actions,
    getters,
    mutations,
};