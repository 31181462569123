import router from "@/router";
import { axiosInstance } from "@/utils";

export default {
    setUser(context, value) {
        context.commit("setUser", value)
    },
    async setToken(context, value) {
        context.commit("setToken", value)
    },
    async setCompanyToken(context, value) {
        context.commit("setCompanyToken", value);
        context.dispatch('getCompany');
    }, 
    logout(context){
        localStorage.removeItem("x-platform-token");
        localStorage.removeItem("x-platform-companyToken");
        context.commit('setUser', {})
        context.commit('setCompany', {})
        
        router.push({name: 'login'})
    },
    /**
     * load preferences and configurations about site
     * @param {*} context 
     */
     async getUser(context){               
        return await axiosInstance({
            method: 'GET',
            url: 'users/info',               
        }).then(response => response.data.data)
        .then(data => {            
            context.commit("setUser", data);

            return data;
        })
    },
    async getCompany(context){               
        return await axiosInstance({
            method: 'GET',
            url: 'users/company-info',               
        }).then(response => response.data.data)
        .then(data => {            
            context.commit("setCompany", data);
            
            return data;
        })
    },
};