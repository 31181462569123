<template>
    <li>
        <template v-if="getLinkMenu != '/'">
            <router-link class="btn relative h-10 w-10 p-0 hover:bg-slate-300/20 
        focus:bg-slate-300/20 active:bg-slate-300/25
         dark:hover:bg-navy-300/20 
         dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25" :to="getLinkMenu"
                @click.native="setLinkMenuSelected(linkMenu)" v-tooltip="linkMenu.label"
                :class="{'bg-slate-150 bg-gradient-to-r from-purple-500 to-purple-600 text-white' : menuActive}">
                <div class="absolute top-0 right-0 flex h-4 min-w-[1rem] 
                items-center justify-center rounded-full bg-success px-1 
                text-tiny font-medium leading-none text-white dark:bg-accent" v-if="linkMenu.counter">
                    {{linkMenu.counter}}
                </div>
                <menu-icon :menu="linkMenu"></menu-icon>
            </router-link>
        </template>
        <template v-else>
            <a class="btn relative h-10 w-10 p-0 hover:bg-slate-300/20 
        focus:bg-slate-300/20 active:bg-slate-300/25
         dark:hover:bg-navy-300/20 
         dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25" :href="linkBackendOld" v-tooltip="linkMenu.label"
                :class="{'bg-slate-150 bg-gradient-to-r from-purple-500 to-purple-600 text-white' : menuActive}">
                <div class="absolute top-0 right-0 flex h-4 min-w-[1rem] 
                items-center justify-center rounded-full bg-success px-1 
                text-tiny font-medium leading-none text-white dark:bg-accent" v-if="linkMenu.counter">
                    {{linkMenu.counter}}
                </div>
                <menu-icon :menu="linkMenu"></menu-icon>
            </a>
        </template>
    </li>
</template>
<script>
import mixinsMenu from '../mixinsMenu';

export default{
    mixins: [mixinsMenu]
}
</script>