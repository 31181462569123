import routePath from "./routes";

const children = [].concat(routePath);
const routes = [{
    path: '/manager',
    name: 'manager',
    component: () => import('@/views/BackendDefault'),
    children: children,    
    meta: {
        bodyClass: 'has-min-sidebar is-header-blur',
        breadcrumb: 'Manager',
    }
}]

export default routes;
