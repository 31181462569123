<template>
    <div   class="sidebar print:hidden">
        <main-sidebar></main-sidebar>
        <minimized-sidebar></minimized-sidebar>
        <panel-sidebar></panel-sidebar>
    </div>
</template>
<script>
import PanelSidebar from './PanelSidebar';
import MainSidebar from './MainSidebar';
import MinimizedSidebar from './MinimizedSidebar';
import { mapGetters } from 'vuex';

export default {
    components:{
        PanelSidebar,
        MainSidebar,
        MinimizedSidebar
    },
    computed: {
        ...mapGetters('User', ['company']),
    }
}
</script>