import { axiosWithoutLoading } from "@/utils";

export default {
    setSitePlugin(context, value) {
        context.commit("setSitePlugin", value)
    },
    setFanPages(context, value){
        context.commit('setFanPages', value)
    },
    setFanPageViewKey(context, value){
        context.commit('setFanPageViewKey', value)
    },
    setFbAppId(context, value){
        context.commit('setFbAppId', value)
    },
    setFbModalActive(context, value){
        context.commit('setFbModalActive', value)
    },
    setFbPageSelected(context, value){
        context.commit('setFbPageSelected', value)
    },
    async getPluginList(context, params){   
        context.commit('setLoading', true);            
        return await axiosWithoutLoading({
            method: 'GET',
            url: 'marketing/leads/plugins',
        }).then(response => response.data.data)
        .then(data => {            
            context.commit("setPlugins", data);
            context.commit('setLoading', false);
            return data;
        })
    },    
};