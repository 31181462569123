// import _ from 'lodash';

export default {  
  setLoading(state, value){
    state.loading = value;
  },  
  setMenuNav(state, value){    
    state.menuNav = value;
  }, 
  setListBlog(state, value){    
    state.listBlog = value;
  }, 
  setRouterBack(state, value){
    state.routerBack = value
  },
  setMenuSelected(state, value){    
    state.menuSelected = value;
  }, 
  setLinkMenuSelected(state, value){
    state.linkMenuSelected = value
  },
  setMenuGroupSelect(state, value){
    state.menuGroupSelect = value;
  },
  setPreferences(state, value){
    let properties =  JSON.parse(localStorage.getItem('x-platform-preferences')) || (state.preferences);
    
    if(!properties){
      properties = {}
    }
    
    state.preferences = Object.assign(properties, value);
    localStorage.setItem('x-platform-preferences', JSON.stringify(state.preferences));
  },
  setSiteConfigurations(state, value){
    state.site = value;
  },
  setHeaderMenu(state, value){
    let properties = (state.headerMenu);
    state.headerMenu = Object.assign(properties, value);    
  }
};