import { randomNumber } from "@/utils";

export default {
    setUnits(state, value) {
        state.units = value;
    },
    setDateRange(state, value) {
        state.dateRange = value;
    },
    setUnitId(state, value){
        state.unitId = value;
    },
    setChartKey(state){
        state.chartKey = randomNumber();
    }
};