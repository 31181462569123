<template>
    <button @click="selectMenu"
        type="button"
        class="flex flex-col items-center flex justify-center rounded-lg outline-none 
        transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 
        active:bg-primary/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25
        relative p-2"
        :class="{'bg-slate-150 bg-gradient-to-r from-purple-500 to-purple-600 text-white' : menuActive}"
        v-tooltip.placement.right="menu.tooltip || menu.label">
        <menu-icon :menu="menu"></menu-icon>
        <span class="text-xs text-dots">{{menu.label}} </span>
        <div v-if="menu.counter" 
            class="inline-flex absolute -top-2 -right-0 justify-center items-center w-5 h-5 text-xs font-bold text-white bg-red-500 rounded-full border-1 border-white dark:border-gray-900"
            style="font-size: 0.60rem">
            {{menu.counter}}
        </div>       
    </button>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import MenuIcon from '../components/MenuIcon.vue';

export default {
    props: {
        menu: Object,
        codeMenu: String,
    },    
    components:{
        MenuIcon
    },
    computed: {
        ...mapGetters('Site', ['linkMenuSelected', 'menuSelected']),
        menuActive(){
            let response = false;            
            let owner = this.menuSelected && Object.keys(this.menuSelected).length ? this.menuSelected.code: null;
            
            if(!owner){
                let explode = document.location.pathname.split("/");                           
                owner = explode.length > 2 ? `${explode[1]}-${explode[2]}`: ``;
            }
            
            if(owner && this.menu.code == owner && this.$route.name != 'home'){
                response = true;
            }
            
            return response;
        }
    },
    methods: {
        ...mapActions('Site', ['setMenuSelected']),
        selectMenu(){
            this.setMenuSelected(this.menu);
        }
    },
}
</script>
<style>
    .text-dots{
        width: 50px; white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    }
</style>