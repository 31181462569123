import i18n from '@/i18n';

export default [
    {
        path: 'registers/units',        
        component: () => import('@/views/BackendDefault'),
        meta: {
            breadcrumb: i18n.t("view.units.title")
        },
        children: [{
                path: 'list',
                name: 'units',
                component: () => import('@/views/BackendDefault/List'),
                meta: {
                    breadcrumb: i18n.t("view.list")
                },
            },
            {
                path: 'create',
                name: 'units-create',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.create")
                },
            },
            {
                path: 'update/:id',
                name: 'units-update',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.update")
                },
            },
        ]
    },
    {
        path: 'registers/departments',
        name: 'departments-index',
        component: () => import('@/views/BackendDefault'),
        meta: {
            breadcrumb: i18n.t("view.departments.title")
        },
        children: [{
                path: 'list',
                name: 'departments',
                component: () => import('@/views/BackendDefault/List'),
                meta: {
                    breadcrumb: i18n.t("view.list")
                },
            },
            {
                path: 'create',
                name: 'departments-create',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.create")
                },
            },
            {
                path: 'update/:id',
                name: 'departments-update',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.update")
                },
            },
        ]
    },  
    {
        path: 'employees/employees',
        name: 'employees-index',
        component: () => import('@/views/BackendDefault'),
        meta: {
            breadcrumb: i18n.t("view.employees.title")
        },
        children: [{
                path: 'list',
                name: 'employees',
                component: () => import('@/views/BackendDefault/List'),
                meta: {
                    breadcrumb: i18n.t("view.list")
                },
            },
            {
                path: 'create',
                name: 'employees-create',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.create")
                },
            },
            {
                path: 'update/:id',
                name: 'employees-update',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.update")
                },
            },
        ]
    },
    {
        path: 'employees/roles',
        name: 'roles-index',
        component: () => import('@/views/BackendDefault'),
        meta: {
            breadcrumb: i18n.t("view.roles.title")
        },
        children: [{
                path: 'list',
                name: 'roles',
                component: () => import('@/views/BackendDefault/List'),
                meta: {
                    breadcrumb: i18n.t("view.list")
                },
            },
            {
                path: 'create',
                name: 'roles-create',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.create")
                },
            },
            {
                path: 'update/:id',
                name: 'roles-update',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.update")
                },
            },
        ]
    },
    {
        path: 'customers/customers',
        name: 'customers-index',
        component: () => import('@/views/BackendDefault'),
        meta: {
            breadcrumb: i18n.t("view.customers.title")
        },
        children: [{
                path: 'list',
                name: 'customers',
                component: () => import('@/views/BackendDefault/List'),
                meta: {
                    breadcrumb: i18n.t("view.list")
                },
            },
            {
                path: 'create',
                name: 'customers-create',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.create")
                },
            },
            {
                path: 'update/:id',
                name: 'customers-update',
                component: () => import('@/views/Company/Customers/Customers/UpdateView'),
                meta: {
                    breadcrumb: i18n.t("view.update"),
                    vuex: {
                        'Company.Customer': () => require('@/views/Company/Customers/Customers/store'),
                    }
                },
            },
        ]
    },
];