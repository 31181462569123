import {cloneDeep} from "lodash";

export default {
    isLoading: state => state.loading,      
    preferences: state => cloneDeep(state.preferences || (JSON.parse(localStorage.getItem('x-platform-preferences')))),    
    headerMenu: state => cloneDeep(state.headerMenu),
    site: state => cloneDeep(state.site),
    menuNav: state => cloneDeep(state.menuNav),
    menuSelected: state => cloneDeep(state.menuSelected),
    linkMenuSelected: state => cloneDeep(state.linkMenuSelected),
    menuGroupSelect: state => cloneDeep(state.menuGroupSelect),
    listBlog: state => cloneDeep(state.listBlog),
    routerBack: state => state.routerBack,
};
