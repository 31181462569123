import { randomNumber } from '@/utils';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
    namespaced: true,
    state: () => ({
        units: [],
        dateRange: null,
        unitId: [],
        chartKey: randomNumber(),
    }),
    actions,
    getters,
    mutations,
};