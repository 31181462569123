<template>
    <div v-if="company && Object.keys(company).length">
        <button @click="openModal" v-tooltip="company.name" class="inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-100 rounded-full dark:bg-gray-600">
            <short-name></short-name>
        </button>        
    </div>
</template>
<script>
import {
mapActions,
    mapGetters
} from 'vuex';
import ShortName from './ShortName.vue';

export default {
    computed: {
        ...mapGetters('User', ['company', 'user']),
    },
    components: {
        ShortName,
    },
    methods: {
        ...mapActions('Modal', ['setModal']),
        openModal(){
            if(this.user.companies.length){
                this.setModal({component: 'ModalListCompanies'})
            }            
        }
    },
}
</script>