import i18n from '@/i18n';

export default [
    {
        path: 'vehicles/vehicleuseds',
        name: 'vehicleuseds-index',
        component: () => import('@/views/BackendDefault'),
        meta: {
            breadcrumb: i18n.t("view.vehicleuseds.title")
        },
        children: [{
                path: 'list',
                name: 'vehicleuseds',
                component: () => import('@/views/BackendDefault/List'),
                meta: {
                    breadcrumb: i18n.t("view.list")
                },
            },
            {
                path: 'create',
                name: 'vehicleuseds-create',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.create")
                },
            },
            {
                path: 'update/:id',
                name: 'vehicleuseds-update',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.update")
                },
            },
        ]
    },    
];