import routePath from "./routes";

const children = [].concat(routePath);
const routes = [{
    path: '/company',
    component: () => import('@/views/BackendDefault'),
    children: children,    
    meta: {
        bodyClass: 'has-min-sidebar is-header-blur',
        breadcrumb: 'Marketing',
    }
}]

export default routes;
