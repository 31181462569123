import store from '@/store';

export async function registerVuexModule(to, from){        
    if('meta' in to && 'vuex' in to.meta){        
        for (const key in to.meta.vuex) {            
            if (Object.hasOwnProperty.call(to.meta.vuex, key)) {
                const modulePath = to.meta.vuex[key];
                
                if(!store.hasModule(key)){
                    store.registerModule(key, modulePath().default);
                }
            }
        }
    }
} 