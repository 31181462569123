<template>
    <div class="main-sidebar">
        <div
            class="flex h-full w-full flex-col items-center border-r border-slate-150 bg-white dark:border-navy-700 dark:bg-navy-800">
            <!-- Application Logo -->
            <div class="flex pt-4">
                <router-link :to="{name: 'home'}"
                    @click.native="setMenuGroupSelect({})">
                    <img v-if="site && site.logo_icon" class="h-11 w-11 transition-transform duration-500 ease-in-out hover:rotate-[360deg]"
                        :src="site.logo_icon_path" alt="logo" />
                    <img v-else class="h-11 w-11 transition-transform duration-500 ease-in-out hover:rotate-[360deg]"
                        src="@/assets/images/app-logo.svg" alt="logo" />
                </router-link>
            </div>

            <!-- Main Sections Links -->
            <div class="is-scrollbar-hidden flex grow flex-col space-y-1 overflow-y-auto pt-6">
                <!-- Dashobards -->
                <router-link :to="{name: 'home'}"
                class="flex flex-col items-center flex justify-center rounded-lg outline-none 
                    transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 
                    active:bg-primary/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25
                    relative p-2"                    
                    :class="{'bg-slate-150 bg-gradient-to-r from-purple-500 to-purple-600 text-white' : (!menuSelected || this.$route.name == 'home')}"
                    @click.native="clickNative()"
                    v-tooltip.placement.right="'Dashboards'">
                    <menu-icon :menu="{icon: 'icon-home'}"></menu-icon>
                    <span class="text-xs">Inicio</span>
                </router-link>

                <div v-for="(menu, index) in getMenuNav" :key="index" class="space-y-1">                    
                    <link-menu
                        :menu="menu"
                        :codeMenu="menu.code"></link-menu>
                </div>
            </div>

            <!-- Bottom Links -->
            <div class="flex flex-col items-center space-y-3 py-3">               
                <!-- Profile -->
                <ProfileButton />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import LinkMenu from './LinkMenu.vue';
import LinkMenuGroup from './LinkMenuGroup.vue';
import MenuIcon from '../components/MenuIcon.vue'
import ProfileButton from './ProfileButton'

export default {
    components:{
        LinkMenu,
        MenuIcon,
        ProfileButton,
        LinkMenuGroup
    },
    computed: {
        ...mapGetters('Site', ['site', 'menuNav', 'linkMenuSelected', 'menuSelected', 'menuGroupSelect']),
        ...mapGetters('User', ['user']),
        getMenuNav(){
            let data = Object.entries(this.menuNav);
            let menuArray = [];

            for (const property in this.menuNav) {
                menuArray.push(this.menuNav[property]);
            }

            return menuArray;
        }
    },
    methods: {
        ...mapActions('Site', ['loadMenuNav', 'setLinkMenuSelected', 'setHeaderMenu', 'setMenuGroupSelect']),
        clickNative(){
            this.setHeaderMenu({isSidebarExpanded: false});
            this.setMenuGroupSelect({})
        }
    },
    mounted() {
        this.loadMenuNav();
    },
}
</script>