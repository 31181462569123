export const ICONS = {
    'icon-leaf': 'fa-solid fa-bullseye',
    "icon-dashboard": "fa-solid fa-chart-area",
    "icon-paste": "fa-solid fa-ranking-star",
    "icon-wechat": "fa-solid fa-comments-dollar",
    "icon-cogs": "fa-solid fa-plug",
    "icon-text-width": "fa-solid fa-keyboard",
    "icon-medium": "fa-solid fa-hashtag",
    "icon-money": "fa-solid fa-piggy-bank",
    "icon-trash": "fa-solid fa-recycle",
    "icon-sitemap": "fa-solid fa-sitemap",
    "icon-home": "fa-solid fa-home",
    "icon-edit": "fa-solid fa-edit",
    "icon-simplybuilt": "fa-brands fa-simplybuilt",
    "icon-group": "fa-solid fa-users",
    "icon-stack-overflow": "fa-brands fa-stack-overflow",
    "icon-robot": "fa-solid fa-robot",
    "icon-automobile": "fa-solid fa-gauge",
    "icon-car": "fa-solid fa-car"
};