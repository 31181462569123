import {cloneDeep} from "lodash";

export default {   
    plugin: state => state.plugin,
    loading: state => state.loading, 
    tabSelected: state => state.tabSelected,
    formFieldDepends: state => cloneDeep(state.formFieldDepends),
    formFields: state => cloneDeep(state.formFields),
    formFieldTabs: state => cloneDeep(state.formFieldTabs),
    valueInField: state => cloneDeep(state.valueInField),
    formFieldRequired: state => cloneDeep(state.formFieldRequired),
    formFieldRepeater: state => cloneDeep(state.formFieldRepeater),
    formValues: state => cloneDeep(state.formValues),
    formFieldFile: state => cloneDeep(state.formFieldFile),
};
