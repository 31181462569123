<template>
    <div  class="sidebar-panel">
        <div class="flex h-full grow flex-col bg-white pl-[var(--main-sidebar-width)] dark:bg-navy-750">
            <!-- Sidebar Panel Header -->
            <div class="flex h-18 w-full items-center justify-between pl-4 pr-1">
                <div class="flex items-center">
                    <div class="avatar mr-3 hidden h-9 w-9 lg:flex">
                        <div class="is-initial rounded-full text-primary">
                            <menu-icon :menu="menuMain"></menu-icon>
                        </div>
                    </div>
                    <p class="text-lg font-medium tracking-wider text-slate-800 line-clamp-1 dark:text-navy-100">
                        {{ menuMain?.label }}
                    </p>
                </div>
                <button @click="setHeaderMenu({isSidebarExpanded: false})"
                    class="btn h-7 w-7 rounded-full p-0 text-primary hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-accent-light/80 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 xl:hidden">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7">
                        </path>
                    </svg>
                </button>
            </div>

            <!-- Sidebar Panel Body -->
            <div class="flex h-[calc(100%-4.5rem)] grow flex-col">
                <div class="is-scrollbar-hidden grow overflow-y-auto">

                    <div class="my-4 mx-4 h-px bg-slate-200 dark:bg-navy-500"></div>
                    
                    <ListUL :listMenuLi="menuMain.sideMenu"></ListUL>

                    <div class="my-4 mx-4 h-px bg-slate-200 dark:bg-navy-500"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ListUL from './ListUL.vue';
import MenuIcon from '../components/MenuIcon.vue';

export default {
    components: {
        ListUL,
        MenuIcon
    },
    computed: {
        ...mapGetters('Site', ['headerMenu', 'menuSelected', 'menuNav']),        
        menuMain(){
            let menuMain = {};

            if(this.menuSelected && Object.keys(this.menuSelected).length && this.menuSelected.sideMenu){
                menuMain = this.menuSelected;
            }else{
                let explode = this.$route.path ? this.$route.path.split("/") : [];
                
                if(explode.length > 2){
                    let owner = `${explode[1]}.${explode[2]}`.toUpperCase();
                    let plugin = `${explode[1]}-${explode[2]}-${explode[3]}`;
                    
                    Object.entries(this.menuNav).filter((elementArray, index) => {
                        if(elementArray[1].code == `${explode[1]}-${explode[2]}`){
                            menuMain = elementArray[1];
                        }
                        
                        // if (elementArray[0].includes(owner)) {
                        //     //percorre os menus para ver se encontra o caminho completo                            
                        //     Object.entries(elementArray[1].sideMenu).map((sideMenuArray) => {
                        //         if(sideMenuArray[0] == plugin){
                        //             //seleciona o menu principal
                        //             menuMain = elementArray[1];
                        //             console.log(menuMain)                         
                        //         }
                        //     })                            
                        // }
                    })
                }
            }

            return menuMain;
        }
    },
    methods: {
        ...mapActions('Site', ['setHeaderMenu'])
    },
}
</script>