import { axiosInstance, axiosWithoutLoading } from "@/utils";

export default {  
    setLoading(context, value){
        context.commit('loading', value)
    },
    setSelectId(context, value){
        context.commit('setSelectId', value)
    },
    setFilterVisible(context, value){
        context.commit('setFilterVisible', value)
    },
    setCustomRecordLink(context, value){
        context.commit('setCustomRecordLink', value)
    },
    setFilterQuery(context, value){
        context.commit('setFilterQuery', value)
    },
    /**
     * seta qual o plugin (pagina) que está na visão do usuário
     * @param {*} context 
     * @param {String} plugin plugin selecionado
     */
    setPlugin(context, plugin){
        context.commit('setPlugin', plugin);
        context.commit('setRecords', {});
        context.commit('setSelectId', []); 
    },
    /**
     * Setter as configurações que estão na visão da pagina
     */
    setListRender(context, plugin){
        context.commit('setListRender', plugin);
    }, 
    setFilterForm(context, value){        
        context.commit('setFilterForm', value);
        context.dispatch('loadListData')
    },
    async setFilterSelected(context, value){
        context.commit('setFilterSelected', value);
    }, 
    async deleteRecords(context){ 
        await axiosInstance({
            method: 'POST',
            url: 'backend/list-delete',
            data: {
                plugin: context.getters.plugin,
                ids: context.getters.selectIds
            }               
        }).then(response => response.data.data)
        .then(() => {                  
            context.commit('setSelectId', []);       
        })
    },   
    /**
     * carrega as estruturas do layout
     * @param {*} context 
     */
    async loadListPreferences(context, plugin){ 
        await axiosInstance({
            method: 'GET',
            url: 'backend/list-preferences',
            params: {
                plugin: plugin,
            }
        }).then(response => response.data.data)
        .then(props => {                  
            context.commit("setListRenderSaved", {props, plugin})       
        })
    },
    /**
     * Carrega os dados da lista do componente
     * @param {*} context 
     */
    async loadListData(context){ 
        //só finaliza depois de renderizar as linhas
        context.commit('loading', true);
        
        let params = {
            plugin: context.getters.plugin,
            query: (context.getters.filterQuery || {}),
            ...context.getters.listRender.filterSelected,
            ...(context.getters.listRender.filterFormSelect || {}),            
        };

        await axiosWithoutLoading({
            method: 'GET',
            url: 'backend/list',
            params: params
        }).then(response => response.data.data)
        .then(data => {                  
            context.commit("setRecords", data)   
            
            // context.commit('setFilterSelected', data.filterSelected)
        })
    },
};