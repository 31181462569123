import { randomNumber } from '@/utils';
import {cloneDeep} from 'lodash';

export default {  
  resetStateForm(state){
    state.tabSelected = null;
    state.formFieldRequired = {};
    state.formFieldTabs = {};
    state.formFieldDepends = {};
    state.formFieldFile = {};
    state.valueInField = {};
    state.formValues = {};
  },
  setTabSelected(state, value){
    state.tabSelected = value;
  },
  setFormFieldFile(state, value){
    let properties = cloneDeep(state.formFieldFile);
    state.formFieldFile = Object.assign(properties, value);
  },
  setValueInField(state, value){
    let properties = cloneDeep(state.valueInField);
    state.valueInField = Object.assign(properties, value);
  },  
  setFormValues(state, value){
    let properties = cloneDeep(state.formValues);
    state.formValues = Object.assign(properties, value);
  },  
  setFormFields(state, value){
    state.formFields = value;
  },
  setFormFieldTabs(state, value){
    state.formFieldTabs = value;
  },   
  setPlugin(state, value){
    state.plugin = value;
  },
  loading(state, value){
    state.loading = value;
  },
  setFormFieldRequired(state, value){    
    state.formFieldRequired = value;
  },
  setFormFieldRepeater(state, value){    
    let props = cloneDeep(state.formFieldRepeater);

    props = Object.assign(props, value);
    state.formFieldRepeater = props;
  },
  setFormFieldDepends(state, value){    
    state.formFieldDepends = value;
  },
  updateFieldDependKey(state, value){    
    let props = cloneDeep(state.formFieldDepends);

    if(value in props){
      props[value].key = randomNumber()
    }

    state.formFieldDepends = props;
  },
  updateFieldDependOptions(state, value){
    let props = cloneDeep(state.formFieldDepends);

    props = Object.assign(props, value);
    state.formFieldDepends = props;
  }
};