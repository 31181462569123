import Vue from 'vue';
import 'floating-vue/dist/style.css'
import {
    // Directives
    VTooltip,
    VClosePopper,
} from 'floating-vue';
import { VueMaskDirective, VueMaskFilter } from 'v-mask'


Vue.directive('mask', VueMaskDirective);
Vue.filter('VMask', VueMaskFilter)
Vue.directive('tooltip', VTooltip)
Vue.directive('close-popper', VClosePopper)
Vue.directive('click-outside', {
    bind(el, binding, vnode) {
        var vm = vnode.context;
        var callback = binding.value;

        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                return callback.call(vm, event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});
