<template>
    <ul class="space-y-1.5 px-2 pt-1 font-inter text-xs+ font-medium">
        <LinkLI v-for="(link, index) in listMenuLi" :key="index" :linkMenu="link"></LinkLI>
    </ul>
</template>
<script>
import LinkLI from './LinkLI.vue';

export default {
    components: {
        LinkLI,
    },
    props: {
        listMenuLi: Object
    },
}
</script>
