var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',[(_vm.getLinkMenu != '/')?[_c('router-link',{staticClass:"group flex justify-between space-x-2 rounded-lg p-2 tracking-wide text-slate-800 outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:text-navy-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600",class:{'bg-slate-150 bg-gradient-to-r from-purple-500 to-purple-600 text-white' : _vm.menuActive},attrs:{"to":_vm.getLinkMenu},nativeOn:{"click":function($event){return _vm.setLinkMenuSelected(_vm.linkMenu)}}},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('menu-icon',{attrs:{"menu":_vm.linkMenu}}),_c('span',{class:{
                    'text-slate-800 dark:text-navy-100': !_vm.menuActive,
                    'text-white': _vm.menuActive
                }},[_vm._v(_vm._s(_vm.linkMenu.label))])],1),(_vm.linkMenu.counter)?_c('span',{staticClass:"badge rounded-full text-xs bg-success text-white"},[_vm._v(_vm._s(_vm.linkMenu.counter))]):_vm._e()])]:[_c('a',{staticClass:"group flex justify-between space-x-2 rounded-lg p-2 tracking-wide text-slate-800 outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:text-navy-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600",attrs:{"href":_vm.linkBackendOld}},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('menu-icon',{attrs:{"menu":_vm.linkMenu}}),_c('span',{class:{
                    'text-slate-800 dark:text-navy-100': !_vm.menuActive,
                    'text-white': _vm.menuActive
                }},[_vm._v(_vm._s(_vm.linkMenu.label))])],1),(_vm.linkMenu.counter)?_c('span',{staticClass:"badge rounded-full bg-success text-white"},[_vm._v(_vm._s(_vm.linkMenu.counter))]):_vm._e()])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }