import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
    namespaced: true,
    state: () => ({
        sitePlugin: {},      
        loading: true  ,
        plugins: [],
        fanPages: [],
        fanPageViewKey: (new Date).getTime(),
        fbAppId: null,
        fbModalActive: false,
        fbPageSelected: {}
    }),
    actions,
    getters,
    mutations,
};