import store from '@/store';
import axios from 'axios';

export async function protectedPage(to, from, next) {
    try {
        let user = await store.getters['User/user'];
        
        if (Object.keys(user).length == 0) {
            user = await store.dispatch('User/getUser');
        }

        // return;
        if (typeof user != 'undefined' && Object.keys(user).length > 0) {
            next();           
        } else {
            next({
                name: "login"
            })
        }
    } catch (e) {
        next({
            name: "login"
        })
    }
}

export function randomNumber() {
    return Math.floor(Math.random() * 1E16)
}

export function getBrwoserScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth;
}

export function leaveAnimation(node, cb, leaveClass = "animate:leave") {
    const styles = window.getComputedStyle(node);
    if (styles.getPropertyValue("display") === "none") return;

    node.classList.add(leaveClass);

    node.addEventListener(
        "animationend",
        () => {
            cb();
            node.classList.remove(leaveClass);
        }, {
            once: true
        }
    );
}

export function isUserAdmin() {
    let user = store.getters['User/user'];
    let result = false;

    if (user.is_superuser) {
        return true;
    }
    
    if (user.role && ['administrator-company', 'administrator-agency', 'administrator-backend'].indexOf(user.role.code) !== -1) {
        result = true;
    }

    return result;
}

export function hasPermission(value) {
    let user = store.getters['User/user'];
    let result = false;

    if (user.is_superuser) {
        return true;
    }
    
    if (user.permissions) {
        if(Array.isArray(user.permissions) && user.permissions.indexOf(value) !== -1){
            result = true;
        }
        
        if(!Array.isArray(user.permissions) && (value in user.permissions)){
            result = true;
        } 
    }

    return result;
}

export function hasProject(value) {
    let company = store.getters['User/company'];
    let result = false;

    if(company && company.projects){
        return (company.projects.filter(element => element.slug == value)).length
    }

    return result;
}

export function getProjectBySlug(value) {
    let company = store.getters['User/company'];
    let result = false;

    if(company && company.projects){
        let project = (company.projects.filter(element => element.slug == value))
        if(project.length){
            result = project[0]
        }
    }

    return result;
}

/**
 * <form>
  <input name="element[el11][el21]" value="a">
  <input name="element[el11][el22]" value="b">
  <input name="element[el12][el21]" value="c">
  <input name="element[el12][el22]" value="d">
</form>
 * saida: {el11: {el21: a} ....}
 * @param {*} inputs 
 */
export async function reduceRepeaterArray(inputs) {    
    var output = {};
    // regex to match `[...]`
    const regex = /(?!^|\[)\w+(?=\]|$)/g

    // Loop over inputs
    for (var i = 0; i < inputs.length; i++) {
        // Get name and matching attrs.
        var attr = inputs[i].getAttribute('name').match(regex);
        console.log(attr)        
        // Create a temp variable to store value of last refered object.
        var t = output;        
        for (var j = 0; j < attr.length; j++) {
            // Initialize values
            if (t[attr[j]] === undefined) {
                t[attr[j]] = {}
            }
            // If last value, assign value to leaf node
            if (j === attr.length - 1)
                t[attr[j]] = inputs[i].value;
            // Set temp to point to current node
            else
                t = t[attr[j]]
        }
    }

    return output;
}
/**
 * 
 * @param {*} nameAttr nome do input com os arrays
 * @param {*} value valor para inserir
 * @returns 
 */
export async function reduceRepeaterInput(nameAttr, value) {    
    var output = {};
    // regex to match `[...]`
    const regex = /(?!^|\[)\w+(?=\]|$)/g

    // Get name and matching attrs.
    var attr = nameAttr.match(regex);   

    // Create a temp variable to store value of last refered object.
    var t = output;        
    for (var j = 0; j < attr.length; j++) {
        // Initialize values
        if (t[attr[j]] === undefined) {
            t[attr[j]] = {}
        }
        // If last value, assign value to leaf node
        if (j === attr.length - 1)
            t[attr[j]] = value;
        // Set temp to point to current node
        else
            t = t[attr[j]]
    }

    return output;
}

/**
 * AxiosInstance contém loading no painel
 */
export const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL_API,
    // withCredentials: false,
});

axiosInstance.interceptors.request.use(function (config) {
    config.headers['X-Panel-Host'] = document.location.hostname;
    config.headers['Authorization'] = store.getters['User/token'];
    config.headers['X-Token-Company'] = store.getters['User/companyToken'];

    store.dispatch('Site/loading', true, {
        root: true
    });

    return config;
});

axiosInstance.interceptors.response.use((response) => {
    store.dispatch('Site/loading', false, {
        root: true
    });

    return response
}, (error) => {
    let dataError = {
        message: ('message' in error.response ? error.response.message : error.response.statusText)
    };

    if ('data' in error.response && error.response.data) {
        dataError = 'data' in error.response.data ? error.response.data.data : error.response.data;
    }

    store.dispatch('Modal/setError', dataError, {
        root: true
    });
    store.dispatch('Site/loading', false, {
        root: false
    });
    // whatever you want to do with the error
    throw error;
});

export const axiosWithoutLoading = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL_API,
    // withCredentials: false,
});

axiosWithoutLoading.interceptors.request.use(function (config) {
    config.headers['X-Panel-Host'] = document.location.hostname;
    config.headers['Authorization'] = store.getters['User/token'];
    config.headers['X-Token-Company'] = store.getters['User/companyToken'];

    return config;
});

axiosWithoutLoading.interceptors.response.use((response) => {
    return response
}, (error) => {
    let dataError = {
        message: ('message' in error.response ? error.response.message : error.response.statusText)
    };

    if ('data' in error.response && error.response.data) {
        dataError = 'data' in error.response.data ? error.response.data.data : error.response.data;
    }

    store.dispatch('Modal/setError', dataError, {
        root: true
    });

    throw error;
});