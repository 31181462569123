import Vue from 'vue';
import VueMoment from 'vue-moment'
import moment from 'moment'
import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    fas
} from '@fortawesome/free-solid-svg-icons'
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
import VueBreadcrumbs from 'vue-2-breadcrumbs'
import Paginate from 'vuejs-paginate'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueTimeago from 'vue-timeago'
import Vue2Filters from 'vue2-filters'
// Or as a directive-only

import VueToastify from "vue-toastify";
import VueFormulate from '@braid/vue-formulate';
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.use(VueFormulate)
Vue.use(VueToastify);

Vue.use(Vue2Filters)
Vue.use(VueTimeago, {
  name: 'timeago', // component name, `timeago` by default
  locale: localStorage.getItem('x-lang') || 'pt-br',
  locales: {    
    'en': require('date-fns/locale/en-US'),
    'pt-br': require('date-fns/locale/pt-BR'),    
  }
})

Vue.component('v-select', vSelect)
Vue.component('paginate', Paginate)
Vue.use(VueSweetalert2);
Vue.use(VueBreadcrumbs);

library.add(fas, fab)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueMoment, {
    moment,
})