<template>
    <span class="font-medium text-gray-600 dark:text-gray-300">{{shortName}}</span>
</template>
<script>
    import { mapGetters } from 'vuex';
    
    export default {
        computed: {
            ...mapGetters('User', ['company']),
            shortName(){
                let name = '';
    
                if(this.company){
                    let explode = this.company.name.split(" ");
                    if(explode.length == 1){
                        name = this.company.name.slice(0,2).toUpperCase()
                    }else{
                        name = explode.map((word) => {  
                            if(word.length > 2)                  
                            return word.slice(0,1).toUpperCase();
                        })
                        
                        name = name.join("");
                    }
                }
    
                return name;
            }
        },
    }
    </script>