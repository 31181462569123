import i18n from '@/i18n';
import store from '@/store';

function beforeEnterPlugin(to, from, next){
    let sitePlugin = store.getters['Marketing.Plugin/sitePlugin'];

    if(Object.keys(sitePlugin).length == 0 || !sitePlugin.id){
        next({
            name: 'plugins.index'
        })
    }else{
        next();
    }
}
export default [
    {
        path: 'leads/dashboard',
        name: 'dashboard',
        component: () => import('@/views/Marketing/Leads/Dashboard'),
        meta: {
            breadcrumb: i18n.t("view.marketing.dashboard.title"),         
            vuex: {
                'Marketing.Dashboard': () => require('@/views/Marketing/Leads/Dashboard/store'),
            },   
        },        
    },
    {
        path: 'leads/captations',
        name: 'captations-index',
        component: () => import('@/views/BackendDefault'),
        meta: {
            breadcrumb: i18n.t("view.captations.title")
        },
        children: [{
                path: 'list',
                name: 'captations',
                component: () => import('@/views/BackendDefault/List'),
                meta: {
                    breadcrumb: i18n.t("view.list")
                },
            },
            {
                path: 'create',
                name: 'captations-create',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.create")
                },
            },
            {
                path: 'update/:id',
                name: 'captations-update',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.update")
                },
            },
        ]
    },
    {
        path: 'leads/midias',
        name: 'midias-index',
        component: () => import('@/views/BackendDefault'),
        meta: {
            breadcrumb: i18n.t("view.midias.title")
        },
        children: [{
                path: 'list',
                name: 'midias',
                component: () => import('@/views/Marketing/Leads/Midias/List'),
                meta: {
                    breadcrumb: i18n.t("view.list")
                },
            },
            {
                path: 'create',
                name: 'midias-create',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.create")
                },
            },
            {
                path: 'update/:id',
                name: 'midias-update',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.update")
                },
            },
        ]
    },
    {
        path: 'leads/unqualifiedreasons',
        name: 'unqualifiedreasons-index',
        component: () => import('@/views/BackendDefault'),
        meta: {
            breadcrumb: i18n.t("view.unqualifiedreasons.title")
        },
        children: [{
                path: 'list',
                name: 'unqualifiedreasons',
                component: () => import('@/views/BackendDefault/List'),
                meta: {
                    breadcrumb: i18n.t("view.list")
                },
            },
            {
                path: 'create',
                name: 'unqualifiedreasons-create',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.create")
                },
            },
            {
                path: 'update/:id',
                name: 'unqualifiedreasons-update',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.update")
                },
            },
        ]
    },
    {
        path: 'leads/social-investiments',
        name: 'social-investiments-index',
        component: () => import('@/views/BackendDefault'),
        meta: {
            breadcrumb: i18n.t("view.social-investiments.title")
        },
        children: [{
                path: 'list',
                name: 'social-investiments',
                component: () => import('@/views/BackendDefault/List'),
                meta: {
                    breadcrumb: i18n.t("view.list")
                },
            },
            {
                path: 'create',
                name: 'social-investiments-create',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.create")
                },
            },
            {
                path: 'update/:id',
                name: 'social-investiments-update',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.update")
                },
            },
        ]
    },
    {
        path: 'leads/inputnames',
        name: 'inputnames-index',
        component: () => import('@/views/BackendDefault'),
        meta: {
            breadcrumb: i18n.t("view.inputnames.title")
        },
        children: [{
                path: 'list',
                name: 'inputnames',
                component: () => import('@/views/BackendDefault/List'),
                meta: {
                    breadcrumb: i18n.t("view.list")
                },
            },
            {
                path: 'create',
                name: 'inputnames-create',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.create")
                },
            },
            {
                path: 'update/:id',
                name: 'inputnames-update',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.update")
                },
            },
        ]
    },
    {
        path: 'leads/contacts',
        name: 'contacts-index',
        component: () => import('@/views/BackendDefault'),
        meta: {
            breadcrumb: i18n.t("view.contacts.title")
        },
        children: [{
                path: 'list',
                name: 'contacts',
                component: () => import('@/views/Marketing/Leads/Contacts/List'),
                meta: {
                    breadcrumb: i18n.t("view.list")
                },
            },
            {
                path: 'create',
                name: 'contacts-create',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.create")
                },
            },
            {
                path: 'update/:id',
                name: 'contacts-update',
                component: () => import('@/views/BackendDefault/CreateUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.update")
                },
            },
            {
                path: 'view/:id',
                name: 'contacts-view',
                component: () => import('@/views/Marketing/Leads/Contacts/ViewDetails'),
                meta: {
                    breadcrumb: i18n.t("view.update")
                },
            },
        ]
    },
    {
        path: 'leads/workflows',
        name: 'workflows-index',
        component: () => import('@/views/BackendDefault'),
        meta: {
            breadcrumb: i18n.t("view.workflows.title"),
            vuex: {
                'Marketing.Workflow': () => require('@/views/Marketing/Leads/Workflows/store'),
            },
        },
        children: [{
                path: 'list',
                name: 'workflows',
                component: () => import('@/views/BackendDefault/List'),
                meta: {
                    breadcrumb: i18n.t("view.list"),
                    vuex: {
                        'Marketing.Workflow': () => require('@/views/Marketing/Leads/Workflows/store'),
                    },
                },
            },
            {
                path: 'create',
                name: 'workflows-create',
                component: () => import('@/views/Marketing/Leads/Workflows/RegisterUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.create"),
                    vuex: {
                        'Marketing.Workflow': () => require('@/views/Marketing/Leads/Workflows/store'),
                    },
                },
            },
            {
                path: 'update/:id',
                name: 'workflows-update',
                component: () => import('@/views/Marketing/Leads/Workflows/RegisterUpdate'),
                meta: {
                    breadcrumb: i18n.t("view.update"),
                    vuex: {
                        'Marketing.Workflow': () => require('@/views/Marketing/Leads/Workflows/store'),
                    },
                },
            },
        ]
    },
    {
        path: 'leads/plugins',
        name: 'plugins-index',
        component: () => import('@/views/BackendDefault'),
        meta: {
            breadcrumb: i18n.t("view.plugins.title"),
            vuex: {
                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
            },        
        },
        children: [{
                path: 'sites',
                name: 'plugins',
                component: () => import('@/views/Marketing/Leads/Plugins'),
                meta: {
                    breadcrumb: i18n.t("view.list"),   
                    vuex: {
                        'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                    },  
                },                
                children: [
                    {
                        path: '/',
                        name: 'plugins.index',
                        component: () => import('@/views/Marketing/Leads/Plugins/ListItemSite'),
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    },
                    {
                        path: 'create',
                        name: 'plugins.register',
                        component: () => import('@/views/Marketing/Leads/Plugins/RegisterPlugin'),
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    },
                    {
                        path: 'update/:id',
                        name: 'plugins.update',
                        component: () => import('@/views/Marketing/Leads/Plugins/RegisterPlugin'),
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    },
                    {
                        path: 'forms',
                        name: 'plugins.forms',
                        component: () => import('@/views/Marketing/Leads/Plugins/FormSites'),
                        beforeEnter: (to, from, next) => {
                            beforeEnterPlugin(to, from, next)
                        },
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    }, 
                    {
                        path: 'forms/create',
                        name: 'plugins.forms.create',
                        component: () => import('@/views/Marketing/Leads/Plugins/FormSites/RegisterUpdate'),
                        beforeEnter: (to, from, next) => {
                            beforeEnterPlugin(to, from, next)
                        },
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    },
                    {
                        path: 'forms/update/:id',
                        name: 'plugins.forms.update',
                        beforeEnter: (to, from, next) => {
                            beforeEnterPlugin(to, from, next)
                        },
                        component: () => import('@/views/Marketing/Leads/Plugins/FormSites/RegisterUpdate'),
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    },
                    /** */   
                    {
                        path: 'tracker-engage',
                        name: 'plugins.tracker',
                        beforeEnter: (to, from, next) => {
                            beforeEnterPlugin(to, from, next)
                        },
                        component: () => import('@/views/Marketing/Leads/Plugins/TrackerEngage'),
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    },    
                    /** */  
                    {
                        path: 'google-forms',
                        name: 'plugins.googleforms',
                        beforeEnter: (to, from, next) => {
                            beforeEnterPlugin(to, from, next)
                        },
                        component: () => import('@/views/Marketing/Leads/Plugins/GoogleForms'),
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    },    
                    /** */ 
                    {
                        path: 'whatsapp',
                        name: 'plugins.whatsapp',
                        component: () => import('@/views/Marketing/Leads/Plugins/FormWhatsapp'),
                        beforeEnter: (to, from, next) => {
                            beforeEnterPlugin(to, from, next)
                        },
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    }, 
                    {
                        path: 'whatsapp/create',
                        name: 'plugins.whatsapp.create',
                        component: () => import('@/views/Marketing/Leads/Plugins/FormWhatsapp/RegisterUpdate'),
                        beforeEnter: (to, from, next) => {
                            beforeEnterPlugin(to, from, next)
                        },
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    },
                    {
                        path: 'whatsapp/update/:id',
                        name: 'plugins.whatsapp.update',
                        beforeEnter: (to, from, next) => {
                            beforeEnterPlugin(to, from, next)
                        },
                        component: () => import('@/views/Marketing/Leads/Plugins/FormWhatsapp/RegisterUpdate'),
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    },                     
                    /** */  
                    {
                        path: 'emails',
                        name: 'plugins.ruleemails',
                        component: () => import('@/views/Marketing/Leads/Plugins/RuleEmails'),
                        beforeEnter: (to, from, next) => {
                            beforeEnterPlugin(to, from, next)
                        },
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    }, 
                    {
                        path: 'emails/create',
                        name: 'plugins.ruleemails.create',
                        component: () => import('@/views/Marketing/Leads/Plugins/RuleEmails/RegisterUpdate'),
                        beforeEnter: (to, from, next) => {
                            beforeEnterPlugin(to, from, next)
                        },
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    },
                    {
                        path: 'emails/update/:id',
                        name: 'plugins.ruleemails.update',
                        beforeEnter: (to, from, next) => {
                            beforeEnterPlugin(to, from, next)
                        },
                        component: () => import('@/views/Marketing/Leads/Plugins/RuleEmails/RegisterUpdate'),
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    }, 
                    /** */
                    {
                        path: 'integrations',
                        name: 'plugins.pluginintegrations',
                        component: () => import('@/views/Marketing/Leads/Plugins/FormIntegrations'),
                        beforeEnter: (to, from, next) => {
                            beforeEnterPlugin(to, from, next)
                        },
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    }, 
                    {
                        path: 'integrations/create',
                        name: 'plugins.pluginintegrations.create',
                        component: () => import('@/views/Marketing/Leads/Plugins/FormIntegrations/RegisterUpdate'),
                        beforeEnter: (to, from, next) => {
                            beforeEnterPlugin(to, from, next)
                        },
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    },
                    {
                        path: 'integrations/update/:id',
                        name: 'plugins.pluginintegrations.update',
                        beforeEnter: (to, from, next) => {
                            beforeEnterPlugin(to, from, next)
                        },
                        component: () => import('@/views/Marketing/Leads/Plugins/FormIntegrations/RegisterUpdate'),
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    }, 
                    /** */
                    {
                        path: 'calltracking',
                        name: 'plugins.plugincalltracking',
                        component: () => import('@/views/Marketing/Leads/Plugins/FormCalltracking'),
                        beforeEnter: (to, from, next) => {
                            beforeEnterPlugin(to, from, next)
                        },
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    }, 
                    {
                        path: 'calltracking/create',
                        name: 'plugins.plugincalltracking.create',
                        component: () => import('@/views/Marketing/Leads/Plugins/FormCalltracking/RegisterUpdate'),
                        beforeEnter: (to, from, next) => {
                            beforeEnterPlugin(to, from, next)
                        },
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    },
                    {
                        path: 'calltracking/update/:id',
                        name: 'plugins.plugincalltracking.update',
                        beforeEnter: (to, from, next) => {
                            beforeEnterPlugin(to, from, next)
                        },
                        component: () => import('@/views/Marketing/Leads/Plugins/FormCalltracking/RegisterUpdate'),
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    }, 
                    /** */                     
                     {
                        path: 'facebook',
                        name: 'plugins.facebook',
                        component: () => import('@/views/Marketing/Leads/Plugins/FacebookPages'),                        
                        meta: {
                            breadcrumb: i18n.t("view.plugins.formsites.title"),
                            vuex: {
                                'Marketing.Plugin': () => require('@/views/Marketing/Leads/Plugins/store'),
                            },
                        },
                    },
                ]
            },           
        ]
    },
];