import { axiosInstance, axiosWithoutLoading, randomNumber } from "@/utils";
import {cloneDeep} from 'lodash'

export default {  
    setLoading(context, value){
        context.commit('loading', value)
    },
    setTabSelected(context, value){
        context.commit('setTabSelected', value)
    },
    /**
     * setter o valor dos campos preenchidos no vuex
     * @param {*} context 
     * @param {*} value 
     */
    setValueInField(context, value){
        context.commit('setValueInField', value)
    },
    setFormValues(context, value){
        context.commit('setFormValues', value)
    },
    updateFieldDependKey(context, value){
        context.commit('updateFieldDependKey', value);
    },
    setFormFieldRepeater(context, value){
        context.commit('setFormFieldRepeater', value)
    },
    setFormFieldFile(context, value){
        context.commit('setFormFieldFile', value)
    },
    // /**
    //  * formata e adiciona o objeto de repeater que vem do backend
    //  * @param {*} context 
    //  * @param {*} value 
    //  */
    // addFormFieldRepeater(context, data){
    //     data.fields.map((elementMain) => {            
    //         if(elementMain.type == 'repeater'){                
    //             let props = {};
    //             let dataMain = data.values[elementMain.id] || [];
                
    //             //existe data no repeater
    //             if(dataMain.length){
    //                 props[elementMain.id] = [];

    //                 //possui um nivel
    //                 if('form' in elementMain){
    //                     for (let index = 0; index < dataMain.length; index++) {
    //                         const elementValues = dataMain[index];     
                                                  
    //                         //inicia o objeto
    //                         props[elementMain.id][index] = {};

    //                         //percorro todos items do repeater
    //                         elementMain.form.fields.map((field) => {                                
    //                             props[elementMain.id][index][field.id] = field;
    //                             //existe o valor de dados que veio do backend

    //                             //percorro todos os items que sao filhos do repeater
    //                             if(field.type == 'repeater'){
    //                                 if(field.id in elementValues){
    //                                     for (let indexChild = 0; indexChild < elementValues[field.id].length; indexChild++) {
    //                                         const elementChild = elementValues[field.id][indexChild] || [];
    //                                         let propsChild = {};
    //                                         //segue mesma logica do main mas para child
    //                                         field.form.fields.map((fieldChild) => {
    //                                             //
    //                                             fieldChild.name = `repeater[${elementMain.id}][${index}][${field.id}][${indexChild}][${fieldChild.id}]`;
    //                                             fieldChild.values = [];
    //                                             //mesma logica do main, verifica se tem dados
    //                                             fieldChild.values[indexChild] = elementChild;
    //                                             propsChild[fieldChild.id] = fieldChild;                                                                                               
    //                                         });
    //                                         props[elementMain.id][index][field.id].values[indexChild] = propsChild;
    //                                     }
    //                                 }else{
    //                                     //segue mesma logica do main mas para child
    //                                     field.form.fields.map((fieldChild, indexChild) => {
    //                                         let propsChild = {};
    //                                         fieldChild.name = `repeater[${elementMain.id}][${index}][${field.id}][${indexChild}][${fieldChild.id}]`;
    //                                         fieldChild.values = [];                                            
    //                                         propsChild[fieldChild.id] = fieldChild;                                                                                               
    //                                         props[elementMain.id][index][field.id].values.push(propsChild);
    //                                     });                                        
    //                                 }                                
    //                             }else{
    //                                 //TODO: if field not is repeater like text, number, select etc
    //                                 let propsChild = {};
    //                                 console.log(elementValues, field)
    //                                 field.name = `repeater[${elementMain.id}][${index}][${field.id}]`;
    //                                 field.values = elementValues[field.id] || [];                                    
    //                                 propsChild[field.id] = field;
    //                                 props[elementMain.id][index][field.id].values = propsChild;
    //                             }

    //                             // if(field.id in element){                                                                  
    //                             //     props[elementMain.id][index][field.id].values = [];

    //                             //     //percorro todos os items que sao filhos do repeater
    //                             //      if(field.type == 'repeater'){                                        
    //                             //         for (let indexChild = 0; indexChild < element[field.id].length; indexChild++) {
    //                             //             const elementChild = element[field.id][indexChild];
    //                             //             let propsChild = {};
    //                             //             //segue mesma logica do main mas para child
    //                             //             field.form.fields.map((fieldChild) => {
    //                             //                 //
    //                             //                 fieldChild.name = `repeater[${elementMain.id}][${index}][${field.id}][${indexChild}][${fieldChild.id}]`;
    //                             //                 fieldChild.values = [];
    //                             //                 //mesma logica do main, verifica se tem dados
    //                             //                 fieldChild.values[indexChild] = elementChild;
    //                             //                 propsChild[fieldChild.id] = fieldChild;                                                                                               
    //                             //             });
    //                             //             props[elementMain.id][index][field.id].values[indexChild] = propsChild;
    //                             //         }
    //                             //     }else{
    //                             //         //TODO: em caso de nao repeater
    //                             //     }
    //                             // }
    //                         })
    //                     }                       
    //                 }                                    
    //             }
                
    //             context.commit("setFormFieldRepeater", props)
    //         }
    //     })        
    // },
    /**
     * dados que veem do banco de dados
     * @param {*} context 
     * @param {*} values 
     */  
    setValueFromBase(context, data){
        let obj = {};
        let values = data.values;
        
        if(values){
            let keys = Object.keys(values);
            
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];            
                const element = values[key];
                
                let field = data.fields.find((filter) => filter.id == key);
                
                /**
                 * fazendo parseInt no element pq pode ter valores que vem 0
                 * e o js nao esta reconhecendo
                 */
                if ((element != "" && element != null && element != "null") || parseInt(element) == 0) {
                    if(field && field.type == 'repeater'){
                        obj[key] = element;
                    }else{
                        // console.log("KEY ", key, "value", element)
                        obj[key] = {
                            value: element
                        };
                    }
                }
            }
        }
        
        context.commit('setValueInField', obj);
    },
    /**
     * seta qual o plugin (pagina) que está na visão do usuário
     * @param {*} context 
     * @param {String} plugin plugin selecionado
     */
    async setPlugin(context, plugin){
        context.commit('setPlugin', plugin);
        context.commit('resetStateForm')
    },  
    async updateFieldDependOptions(context, {nameId, dependsOn}){
        let obj = {};
        obj[nameId] = dependsOn;
        
        context.commit('updateFieldDependOptions',obj)
    },
    async setFormFields(context, fields){
        //analisa se os campos possuem triggers ou depends para carregar
        //TODO:
        context.commit('setFormFields', fields);
    },
    /**
     * Setter campos que são dependends dos formulários do october
     * exemplo, se o campo do formulario possuir a propriedade "dependsOn"
     * seta aqui o nome do campo e os seus filhos, causando assim um update
     * automatico no dropdown do mesmo.
     * @param {*} context 
     * @param {*} fields 
     */
    async setFormFieldDepends(context, fields){
        let dependsOn = cloneDeep(context.getters.formFieldDepends);

        for (const key in fields) {
            if (Object.hasOwnProperty.call(fields, key)) {
                const element = fields[key];
                
                if('dependsOn' in element){
                    let dependsIndex = Array.isArray(element.dependsOn) ? element.dependsOn[0] : element.dependsOn;
                    
                    if(!(dependsIndex in dependsOn)){
                        dependsOn[dependsIndex] = {
                            fields: {},
                            key: null,
                        };
                    }

                    if(!(element.id in dependsOn[dependsIndex].fields)){
                        dependsOn[dependsIndex].fields[element.id] = element;
                    }

                    dependsOn[dependsIndex].key = randomNumber();                    
                }
            }
        }        
        context.commit('setFormFieldDepends', dependsOn);
    },
    async loadDependOptions(context, formData){
        return await axiosInstance({
            method: 'POST',
            url: 'backend/form-depend-options',
            data: formData             
        }).then(response => response.data.data)
        .then(data => {                          
            // console.log(data);
            return data;
        })
    },
    /**
     * carrega as estruturas do layout
     * @param {*} context 
     */
    async loadFormPreferences(context, {plugin, id}){ 
        await axiosInstance({
            method: 'GET',
            url: 'backend/form-preferences',
            params: {
                plugin: plugin,
                id: id
            }               
        }).then(response => response.data.data)
        .then(async data => {                          
            context.dispatch("setFormFields", data.fields)
            await context.dispatch('setFormFieldDepends', data.fields);
            // context.dispatch('addFormFieldRepeater', data);
            context.commit("setFormFieldTabs", data.tabs)
            context.commit("setFormFieldRequired", data.required)
            context.dispatch("setValueFromBase", data)            
        })
    },
    async saveUpdate(context, formData){
        await axiosInstance({
            method: 'POST',
            url: 'backend/form-save-data',
            data: formData             
        }).then(response => response.data.data)
        .then(data => {                          
            // console.log(data);
        })
    }
};