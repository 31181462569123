import Vue from 'vue'
import Vuex from 'vuex'
import Site from './Site';
import User from './User';
import Modal from './Modal';
import List from './List';
import Form from './Form';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Site,
    User,
    Modal,
    List,
    Form
  }
})
