<template>
    <div class="header-container relative flex w-full bg-white dark:bg-navy-750 print:hidden">
        <!-- Header Items -->
        <div class="flex w-full items-center justify-between">
            <!-- Left: Sidebar Toggle Button -->
            <toggle-menu></toggle-menu>

            <!-- Right: Header buttons -->
            <div class="-ml-1.5 flex items-center space-x-2 space-x-reverse">
                <!-- Mobile Search Toggle -->
                <toggle-search-mobile></toggle-search-mobile>

                <!-- Dark Mode Toggle -->
                <toggle-dark-mode></toggle-dark-mode>                

                <!-- Notification-->                
                <notifications></notifications>

                <button-change-company></button-change-company>
            </div>
        </div>
    </div>
</template>
<script>
import ToggleMenu from './ToggleMenu';
import ToggleDarkMode from './ToggleDarkMode';
import ToggleSearchMobile from './ToggleSearchMobile';
import Notifications from './Notifications';
import ButtonChangeCompany from './ButtonChangeCompany';

export default {
    components: {
        ToggleMenu,
        ToggleDarkMode,
        ToggleSearchMobile,        
        Notifications,
        ButtonChangeCompany
    }
}
</script>