import store from '@/store';

export default function money (value, optionsMoney = 'company') {    
    if (isNaN(value)) {
        return value;
    }

    var decimals = value < 0.01 ? 3 : 2;
    let currency, currencyCountry, symbol;

    currency = store.getters['User/company'].moeda || 'BRL';
    currencyCountry = store.getters['User/company'].moeda || 'pt-BR';
    symbol = store.getters['User/company'].moeda || 'R$';

    if(optionsMoney == 'agency'){
        let props = store.getters['User/company'].agency.currencies; 
        currency = props.currency;
        currencyCountry = props.currencyCountry;
        symbol = props.symbol;
        decimals = props.decimals;        
    }    

    var formatter = new Intl.NumberFormat(currencyCountry, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: decimals
    });

    return formatter.format(value);
}