<template>
    <div id="sidebar-panel-min" :key="routeName" class="sidebar-panel-min" 
        :class="{
            hidden: hiddenPanel
        }">
        <div class="flex h-full flex-col items-center bg-white dark:bg-navy-750">
            <div v-tooltip=" menuSelected?.label" class="flex h-18 shrink-0 items-center justify-center">
                <div class="avatar flex h-10 w-10 rounded-full text-primary">
                    <div class="is-initial">
                        <menu-icon v-tooltip="menuMain.label" :menu="menuMain"></menu-icon>
                    </div>
                </div>
            </div>

            <div class="flex h-[calc(100%-4.5rem)] grow flex-col">
                <div class="is-scrollbar-hidden flex grow flex-col overflow-y-auto">
                    <ListUL :listMenuLi="menuMain.sideMenu" />
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import ListUL from './ListUL.vue';
import MenuIcon from '../components/MenuIcon.vue';

export default {
    components: {
        ListUL,
        MenuIcon
    },
    data() {
        return {
            menuShow: false,
            routeName: 'home'
        }
    },
    computed:{
        ...mapGetters('Site', ['headerMenu', 'menuSelected', 'menuNav']),
        hiddenPanel(){
            return ['home', 'profile'].indexOf(this.$route.name) !== -1 
                || (['home', 'profile'].indexOf(this.$route.name) === -1 && this.headerMenu.isSidebarExpanded);
        },
        // menuMain() {
        //     let menuMain = {};

        //     if (this.menuSelected && Object.keys(this.menuSelected).length && this.menuSelected.sideMenu) {
        //         menuMain = this.menuSelected;
        //     } else {
        //         let explode = this.$route.path ? this.$route.path.split("/") : [];

        //         if (explode.length > 2) {
        //             let codeUnique = `${explode[1]}`.toUpperCase();
        //             let owner = `${explode[1]}.${explode[2]}`.toUpperCase();
        //             let plugin = `${explode[1]}-${explode[2]}-${explode[3]}`;

        //             if (this.menuNav && Object.keys(this.menuNav).length) {
        //                 // Object.entries(this.menuNav[codeUnique].menus).filter((elementArray, index) => {
        //                 Object.entries(this.menuNav.sideMenu).filter((elementArray, index) => {

        //                     if(elementArray[1].code == `${explode[1]}-${explode[2]}`){
        //                         menuMain = elementArray[1];
        //                     }

        //                     //loop nos groups de menus
        //                     // if (elementArray[1].sideMenu) {
        //                     //     //percorre os menus para ver se encontra o caminho completo                            
        //                     //     Object.entries(elementArray[1].sideMenu).map((sideMenuArray) => {                                    
        //                     //         if (sideMenuArray[0] == plugin) {
        //                     //             //seleciona o menu principal
        //                     //             menuMain = elementArray[1];                                        
        //                     //         }
        //                     //     })
        //                     // }
        //                 })
        //             }
        //         }
        //     }

        //     return menuMain;
        // }
        menuMain(){
            let menuMain = {};

            if(this.menuSelected && Object.keys(this.menuSelected).length && this.menuSelected.sideMenu){
                menuMain = this.menuSelected;
            }else{
                let explode = this.$route.path ? this.$route.path.split("/") : [];
                
                if(explode.length > 2){
                    let owner = `${explode[1]}.${explode[2]}`.toUpperCase();
                    let plugin = `${explode[1]}-${explode[2]}-${explode[3]}`;
                    
                    Object.entries(this.menuNav).filter((elementArray, index) => {
                        if(elementArray[1].code == `${explode[1]}-${explode[2]}`){
                            menuMain = elementArray[1];
                        }
                        
                        // if (elementArray[0].includes(owner)) {
                        //     //percorre os menus para ver se encontra o caminho completo                            
                        //     Object.entries(elementArray[1].sideMenu).map((sideMenuArray) => {
                        //         if(sideMenuArray[0] == plugin){
                        //             //seleciona o menu principal
                        //             menuMain = elementArray[1];
                        //             console.log(menuMain)                         
                        //         }
                        //     })                            
                        // }
                    })
                }
            }

            return menuMain;
        }
    },
}
</script>