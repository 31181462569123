import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    /**
     * @var array lista de ids selecionadas na tabela
     */
    selectIds: [],
    /**
     * @var boolean loading usado em listagem de dados
     */
    loading: true,
    /**
     * @var object records de um plugin
     */
    records: {},
    /**
     * @var object com estrutura da lista de dados da pagina selecionada
     */
    listRender: {},
    /**
     * @var string plugin que está selecionado no frontend (pagina em render)
     */
    plugin: null,
    /**
     * @var object lista de estruturas acessadas recentementes
     */
    listRenderSaved: {},  
    /**
     * @var string url personalizada que redireciona o usuario para editar registro
     */
    recordLink: null,
    /**
     * @var object campos que serão usados de maneira fixa em filtro de listagem
     */
    filterQuery: {}
  },
  actions,
  getters,
  mutations,
};