import {cloneDeep} from "lodash";

export default {   
    listRender: state => cloneDeep(state.listRender),    
    listRenderSaved: state => cloneDeep(state.listRenderSaved),
    records: state => cloneDeep(state.records),
    plugin: state => state.plugin,
    loading: state => state.loading,
    selectIds: state => cloneDeep(state.selectIds),
    recordLink: state => state.recordLink,    
    filterQuery: state => cloneDeep(state.filterQuery),
};
