export default {
    setUnits(context, value) {
        context.commit('setUnits', value)        
    },
    setUnitId(context, value) {
        context.commit('setUnitId', value)        
        context.commit('setChartKey')
    },
    setDateRange(context, value) {
        context.commit('setDateRange', value);
        context.commit('setChartKey')
    },
};