<template>
    <div>
        <button @click="showSubMenu"
        type="button"
        class="flex flex-col items-center flex justify-center rounded-lg outline-none 
        transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 
        active:bg-primary/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25
        relative p-2"
        :class="{'bg-slate-150 bg-gradient-to-r from-purple-500 to-purple-600 text-white' : menuActive}"
        v-tooltip.placement.right="menu.tooltip || menu.label">
        <menu-icon :menu="menu"></menu-icon>
        <span class="text-xs text-dots">{{menu.label}} </span>
        <div v-if="menu.counter" 
            class="inline-flex absolute -top-2 -right-0 justify-center items-center w-5 h-5 text-xs font-bold text-white bg-red-500 rounded-full border-1 border-white dark:border-gray-900"
            style="font-size: 0.60rem">
            {{menu.counter}}
        </div>       
    </button>
    <div :id="'menu-'+menu.codeUnique" class="bg-slate-300 text-gray rounded-lg mb-1 link-menu-grouped hidden">
        <button 
            v-for="(subMenu, subIndex) in menu.menus"
            :key="subIndex"
            @click="selectMenu(subMenu)"
            type="button"
            class="flex flex-col items-center flex justify-center rounded-lg outline-none 
            transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 
            active:bg-primary/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25
            relative p-2 mt-1"
            :class="{'bg-slate-150 bg-gradient-to-r from-purple-400 to-purple-400 text-white' : subMenuActive(subMenu)}"
            v-tooltip.placement.right="subMenu.tooltip || subMenu.label">
            <menu-icon :menu="subMenu"></menu-icon>
            <span class="text-xs text-dots">{{subMenu.label}} </span>
            <div v-if="subMenu.counter" 
                class="inline-flex absolute -top-2 -right-0 justify-center items-center w-5 h-5 text-xs font-bold text-white bg-red-500 rounded-full border-1 border-white dark:border-gray-900"
                style="font-size: 0.60rem">
                {{subMenu.counter}}
            </div>       
        </button>
        <hr>
    </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import MenuIcon from '../components/MenuIcon.vue';

export default {
    data() {
        return {
            isActive: false,
        }
    },
    props: {
        menu: Object,
        codeMenu: [String, Array],
    },    
    components:{
        MenuIcon
    },
    computed: {
        ...mapGetters('Site', ['linkMenuSelected', 'menuSelected', 'menuGroupSelect']),  
        menuActive(){
            let response = false;            
            let owner = this.menuGroupSelect && Object.keys(this.menuGroupSelect).length ? this.menuGroupSelect.codeUnique: null;
            
            if(!owner){
                let explode = document.location.pathname.split("/");                           
                // owner = explode.length > 2 ? `${explode[1]}-${explode[2]}-${explode[3]}`: ``;
                owner = explode.length > 2 ? `${explode[1]}`: ``;
                owner = owner.toUpperCase();
            }
            
            if(owner && this.menu.codeUnique == owner && this.$route.name != 'home'){                
                response = true;      
                document.querySelector("#menu-"+this.menu.codeUnique).style.display = 'block';          
            }
            
            return response;
        },         
    },
    methods: {
        ...mapActions('Site', ['setMenuSelected', 'setMenuGroupSelect', 'setHeaderMenu']),             
        showSubMenu(){     
            let divs = document.querySelectorAll('.link-menu-grouped');
            for(const div of divs){
                div.style.display = 'none'
            }
            
            this.setHeaderMenu({isSidebarExpanded: false});  
            document.querySelector("#sidebar-panel-min").style.display = 'none';            
            this.setMenuGroupSelect(this.menu)
            document.querySelector("#menu-"+this.menu.codeUnique).style.display = 'block';
        },
        selectMenu(menu){
            this.setMenuSelected(menu);
        },
        subMenuActive(menu){
            let response = false;            
            let owner = this.menuSelected && Object.keys(this.menuSelected).length ? this.menuSelected.code: null;
            
            if(!owner){
                let explode = document.location.pathname.split("/");                           
                owner = explode.length > 2 ? `${explode[1]}-${explode[2]}`: ``;
            }
            
            if(owner && menu.code == owner && this.$route.name != 'home'){
                response = true;
            }
            
            return response;
        }
    },  
}
</script>
<style>
    .text-dots{
        width: 50px; white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    }
</style>