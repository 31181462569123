import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    loading: false,    
    preferences: null,
    error: {},   
    menuNav: {},
    menuSelected: {},
    linkMenuSelected: {},
    menuGroupSelect: {},
    routerBack: null,
    headerMenu: {
      isSearchbarActive: false,
      isSidebarExpanded: false,
      isNotificationActive: false
    },
    listBlog: {}
  },
  actions,
  getters,
  mutations,
};