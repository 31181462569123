<template>
    <font-awesome-icon :icon="getIcon" class="w-5 h-5 fill-slate-800" />
</template>
<script>    
import {ICONS} from './ConstIcons';

export default {
    props: {
        menu: Object,
    },
    computed:{
        getIcon(){
            return ICONS[this.menu.icon] || 'fa-solid fa-arrow-right'
        }
    },    
}
</script>