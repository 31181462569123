export default {
    setSitePlugin(state, value) {
        state.sitePlugin = value;
    },
    setPlugins(state, value) {
        state.plugins = value;
    },
    setLoading(state, value) {
        state.loading = value;
    },
    setFanPages(state, value) {
        state.fanPages = value;
    },
    setFanPageViewKey(state, value) {
        state.fanPageViewKey = value;
    },
    setFbAppId(state, value){
        state.fbAppId = value;
    },
    setFbModalActive(state, value){
        state.fbModalActive = value;
    },
    setFbPageSelected(state, value){
        state.fbPageSelected = value;
    },
};