<template>
    <div class="fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
        v-show="true" role="dialog">
        <div class="absolute inset-0 bg-slate-900/60 backdrop-blur transition-opacity duration-300"></div>
        <div class="relative max-w-lg rounded-lg bg-white px-4 py-10 text-center transition-opacity duration-300 dark:bg-navy-700 sm:px-5"
        style="min-width: 360px;">
            <svg xmlns="http://www.w3.org/2000/svg" class="inline h-28 w-28 text-success" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                      </svg>

            <div class="mt-4">
                <h2 class="text-2xl text-slate-700 dark:text-navy-100">
                    {{$t("modal.alert.title")}}
                </h2>
                <p class="mt-2" v-html="alert.message"></p>
                <button @click="close"
                    class="btn mt-6 bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90">
                    {{$t("button.close")}}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters('Modal', ['alert'])
    },
    methods: {
        ...mapActions('Modal', ['setAlert']),
        close(){                        
            if('url' in this.alert){
                this.$router.push({
                    name: this.alert.url,
                })
            }
            this.setAlert({show: false});
        }
    },
}
</script>