export default {
    setUser(state, value) {
        state.user = value;
    },
    setToken(state, value) {
        state.token = value;
        localStorage.setItem('x-platform-token', value)
    },
    setCompanyToken(state, value) {
        state.companyToken = value;
        localStorage.setItem('x-platform-companyToken', value)
    },
    setCompany(state, value) {
        state.company = value;
    },
};