import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    loading: true,
    /**
     * @var string plugin da pagina em visualizacao
     */
    plugin: null,
    /**
     * @var object campos de formulario
     */
    formFields: {},
    /**
     * @var object campos de formulario que estão em tabs
     */
     formFieldTabs: {},
    /**
     * @var object campos obrigatorios
     */
    formFieldRequired: {},
    /**
     * @var object valores que sao inseridos em repeater
     */
    formFieldRepeater: {},
    /**
     * @var object campos que sao dependentes no ponto em que
     * carrega options
     */
     formFieldDepends: {},
     /**
     * @var string tab selecionada no front
     */
    tabSelected: null,
    /**
     * @var object campos que são preenchidos no formulario
     */
    valueInField: {},
    formValues: {},
    formFieldFile: {}
  },
  actions,
  getters,
  mutations,
};