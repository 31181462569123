import _ from 'lodash';

export default {  
  setPlugin(state, value){
    state.plugin = value;
  },
  loading(state, value){
    state.loading = value;
  },
  setRecords(state, value){
    state.records = value;
  },
  setCustomRecordLink(state, value){
    state.recordLink = value;
  },
  setFilterQuery(state, value){
    state.filterQuery = value;
  },
  setFilterVisible(state, value){    
    let listRender = _.cloneDeep(state.listRender);
    
    if(!('filterVisible' in listRender)){
      listRender.filterVisible = value;
    }

    listRender.filterVisible = value;

    state.listRender = listRender;
  },
  /**
   * esse metodo vem dos formularios
   * tecnicamente cria um novo objeto na listRender mas ao mesmo tempo
   * faz merge no filterSelected esse objeto 
   * foi necessario por que estava tendo erro no momento de merge do filterselected
   * @param {*} state 
   * @param {*} value 
   */
  setFilterForm(state, value){    
    let listRender = _.cloneDeep(state.listRender);
    
    if(!('filterFormSelect' in listRender)){
      listRender.filterFormSelect = {};
    }
    
    listRender.filterFormSelect = Object.assign(listRender.filterFormSelect, value);
    listRender.filterSelected = Object.assign(listRender.filterSelected, listRender.filterFormSelect);
    state.listRender = listRender;
  },
  /**
   * adicionar dados no filterselected que será enviado para o backend no retorno
   * da listagem
   * @param {*} state 
   * @param {*} value 
   */
  setFilterSelected(state, value){
    let listSaved = _.cloneDeep(state.listRenderSaved);
    let properties = listSaved[state.plugin];
    
    if(!properties.filterSelected){
      properties.filterSelected = {};
    }

    properties.filterSelected = Object.assign(properties.filterSelected, value);
    
    state.listRender = properties;
  },
  setSelectId(state, value){    
    state.selectIds = value
  },
  setListRender(state, plugin){
    let listSaved = _.cloneDeep(state.listRenderSaved);
    state.listRender = listSaved[plugin];
  },
  setListRenderSaved(state, {props, plugin}){    
    let properties = _.cloneDeep(state.listRenderSaved);

    if(!(plugin in properties)){
      properties[plugin] = {}
    }

    properties[plugin] = props;  

    state.listRenderSaved = Object.assign(properties, props);
  },    
};