import { axiosInstance } from "@/utils";

export default {
    loading(context, value) {
        context.commit('setLoading', value)
    }, 
    /**
     * retorna uma parte da url
     * @param {*} context 
     * @param {integer} value do segmento pra retornar
     * @returns {String}
     */
    segment(context, value){
        //primeira ocorrencia remove
        let path = document.location.pathname.replace("/","").split("/");
        return path[value];
    },
    /**
     * retorna a ultima string da url
     * @param {*} context      
     * @returns {String}
     */
    lastSegment(context, ignoreNumber = false){
        //primeira ocorrencia remove
        let path = document.location.pathname.replace("/","").split("/");
        let lastItem = path.slice(-1).pop();
        
        //se for numero e se ignora pega o antecessor        
        if(!isNaN(lastItem) && ignoreNumber){
            lastItem = path[path.length - 2]
        }
        
        return lastItem;
    },
    /**
     * setter values to preferences to user in page
     * @param {*} context 
     * @param {Object} value 
     */
    setPreferences(context, value) {        
        context.commit('setPreferences', value);
        context.dispatch('loadPreferences');
    },
    setMenuSelected(context, value){        
        context.commit('setMenuSelected', Object.assign({key: (new Date).getTime()}, value));
        context.dispatch('setHeaderMenu', {isSidebarExpanded: true});
    },
    setLinkMenuSelected(context, value){        
        context.commit('setLinkMenuSelected', value);
    },
    setRouterBack(context, value){
        context.commit('setRouterBack', value);
    },
    setMenuGroupSelect(context, value){
        context.commit('setMenuGroupSelect', value);
        
        if(Object.keys(value).length == 0){        
            let divs = document.querySelectorAll('.link-menu-grouped');
            divs.forEach((div) => {                
                div.style.display = 'none'
            })
        }
    },
    /**
     * setter values to header menu events
     * @param {*} context 
     * @param {Object} value 
     */
    setHeaderMenu(context, value) {
        context.commit('setHeaderMenu', value);  
        context.dispatch('loadHeaderMenu')      
    },  
    loadPreferences(context){          
        let preferences = context.getters.preferences;

        if(preferences && 'isDarkMode' in preferences && preferences.isDarkMode){
            document.querySelector("html").classList.add('dark')
        }else{
            document.querySelector("html").classList.remove('dark')
        }
    },
    loadHeaderMenu(context){     
        let headerMenu = context.getters.headerMenu;

        if(headerMenu.isSidebarExpanded){
            document.querySelector("body").classList.add('is-sidebar-open')
        }else{
            document.querySelector("body").classList.remove('is-sidebar-open')
        }
    },
    /**
     * load preferences and configurations about site
     * @param {*} context 
     */
    async loadSiteConfigurations(context){             
        await axiosInstance({
            method: 'GET',
            url: 'backend/configurations',               
        }).then(response => response.data.data)
        .then(data => {            
            context.commit("setSiteConfigurations", data);            
        })
    },    
    async loadListBlog(context, params){             
        await axiosInstance({
            method: 'GET',
            url: 'web/blog',
            params
        }).then(response => response.data.data)
        .then(data => {            
            context.commit("setListBlog", data);            
        })
    },
    /**
     * load  menu main site
     * @param {*} context 
     */
     async loadMenuNav(context){             
        await axiosInstance({
            method: 'GET',
            url: 'backend/menu-nav',               
        }).then(response => response.data.data)
        .then(data => {                  
            context.commit("setMenuNav", data);            
        })
    },
};