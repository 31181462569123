export default [{
    path: '/auth/login',
    name: 'login',
    component: () => import('@/views/Auth/Login')
},
{
    path: '/auth/recover-password',
    name: 'recoverPassword',
    component: () => import('@/views/Auth/RecoverPassword')
},
{
    path: '/auth/reset/:userId/:code',
    name: 'resetPassword',
    component: () => import('@/views/Auth/ResetPassword')
}];