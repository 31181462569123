<template>
    <div id="app" :key="company?.id" class="min-h-100vh flex grow bg-slate-50 dark:bg-navy-900">
        <loading :active.sync="isLoading" :is-full-page="true"></loading>
        
        <template 
          v-if="user && Object.keys(user).length 
            && ['payment-not-found'].indexOf(this.$route.name) == -1">
          <sidebar></sidebar>
          <app-header></app-header>
        </template>
        
        <router-view :key='$route.fullPath' />

        <modal-app></modal-app>
    </div>
</template>
<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {
mapActions,
    mapGetters
} from 'vuex';
import AppHeader from '@/components/Layout/AppHeader';
import Sidebar from '@/components/Layout/Sidebar';
import ModalApp from '@/components/ModalApp';

export default {
    components: {
        Loading,
        AppHeader,
        Sidebar,
        ModalApp
    },
    computed: {
        ...mapGetters('Site', ['isLoading']),
        ...mapGetters('User', ['user', 'company'])
    },
    methods: {
      ...mapActions('Site', ['loadPreferences', 'loadSiteConfigurations']),
      ...mapActions('User', ['getCompany']),
    },
    async mounted() {
      await this.loadSiteConfigurations();

      this.$nextTick(async () => {        
        this.loadPreferences();

        if(this.company && Object.keys(this.company).length == 0){
          await this.getCompany();
        }
      })
    },
}
</script>
<style>
  .bg-danger{
    background-color: #ff5724;
  }
</style>
