import { mapActions, mapGetters } from 'vuex';
import MenuIcon from './components/MenuIcon.vue';

export default {
    props: {
        linkMenu: Object
    },
    components: {
        MenuIcon
    },
    computed: {
        ...mapGetters('Site', ['linkMenuSelected']),
        ...mapGetters('User', ['token', 'companyToken']),
        linkBackendOld(){
            return `/api/v2.5/redirect?redirect=${this.linkMenu.url}&access_token=${this.token}&token=${this.companyToken}`;
        },
        getLinkMenu(){            
            let owner = this.linkMenu.owner.replace('.', "-").toLowerCase();
            let codeMenu = this.linkMenu.code.replace(`${owner}-`, '');
            
            let link = this.$router.resolve({name: codeMenu});
            let href = link.href;
        
            if(!href){
                console.log(`aquiii -> ${owner}${codeMenu}`)
                link = this.$router.resolve({name: `${owner}${codeMenu}`});
                href = link.href;
            }

            return href;            
        },     
        menuActive(){
            let response = false;                        

            if(this.linkMenuSelected.code == this.linkMenu.code){
                response = true;
            }
            
            if(this.linkMenuSelected && Object.keys(this.linkMenuSelected).length == 0){
                let owner = document.location.pathname.replace("/","").replaceAll("/", "-");
                
                return (owner == this.linkMenu.code || owner.includes(this.linkMenu.code))
            }

            return response;
        }
    },
    methods: {
        ...mapActions('Site', ['setLinkMenuSelected'])
    },
}